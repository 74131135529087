import React, { Component } from 'react';
import { DateTime } from 'luxon';
import { Button, BackToButton, LineItem } from '@lexcelon/react-util';

import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails, Container, Typography } from '@mui/material';
import ImageViewer from 'react-simple-image-viewer';

import { setError } from '../../../alerts';

import { getTest } from '../../../api';

import { ExpandMore } from '@mui/icons-material';

const INITIAL_STATE = {
  test: null,
  imageViewerTest: null,
  isOtherImageViewerOpen: false,
  selectedTreatments: [],
  notes: '',
  isLoading: false,
  sendResultsToOwner: true,
  inViewFoundParasites: [],
  inEditRecommendations: false,
  parentTest: null,
  resultsTests: []
};

class Test extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    this.id = this.props.match?.params?.id;
    if (process.env.NODE_ENV !== 'development' || (process.env.NODE_ENV === 'development' && this.hasMountedAlready === true /* see note below */)) {
      getTest(this.id).then(test => {
        this.setState({ test });
        console.log(test);

        // Retrieve all child tests
        const testIds = [];
        if (test.getJointChildTests() != null && test.getJointChildTests().length > 0) {
          test.getJointChildTests().forEach(jointTest => {
            if (jointTest.getChildTests()?.length > 0) {
              jointTest.getChildTests().forEach(childTest => testIds.push(childTest.getId()));
            }
            else testIds.push(jointTest.getId());
          });
        }
        else if (test.getChildTests()?.length > 0) {
          test.getChildTests().forEach(childTest => testIds.push(childTest.getId()));
        }

        if (testIds.length === 0) this.setState({ resultsTests: [test] });
        else {
          const promises = testIds.map(testId => getTest(testId));
          Promise.all(promises).then(tests => {
            this.setState({ resultsTests: tests });
          }).catch(error => console.log(error));
        }

        // Retrieve the parent test, if any
        const parentTestId = test.getParentTestId() ?? test.getJointParentTestId();
        if (parentTestId != null) {
          getTest(parentTestId).then(parentTest => {
            this.setState({ parentTest });
          }).catch(error => console.log(error));
        }
      }).catch(error => {
        setError(error ?? 'Error: Unable to retrieve test. Please try again.');
        this.setState({ redirect: true });
      });
    }

    /*
    * In dev mode, React.StrictMode renders everything twice, which is a huge problem when
    * we're talking about a connection to the instrument. To accommodate that without having
    * to get rid of React.StrictMode altogether (it is not granular), we check in development
    * mode to see if this is the second render before connecting to the websockets
    */
    this.hasMountedAlready = true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.match?.params?.id !== prevProps.match?.params?.id) {
      this.setState(INITIAL_STATE, () => {
        this.componentDidMount();
      });
    }
  }

  render() {
    const locationState = { backTo: { pathname: this.props.location, description: this.state.test?.getTestType()?.getName() } };
    return (
      <Container style={{ paddingTop: '20px', paddingBottom: '30px' }}>
        {this.props.location?.state?.backTo != null &&
        <BackToButton to={this.props.location.state.backTo.pathname} description={this.props.location.state.backTo.description} />}

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '0.2em' }}>{this.state.test?.getTestType().getName()} Results</Typography>
        {this.state.test?.getAnimal() != null &&
        <Typography variant='subtitle1' style={{ textAlign: 'center', marginBottom: '2em', fontSize: '20px', fontWeight: 'bold', color: 'slategrey' }}>
          Patient: {this.state.test.getAnimal()?.getName()}
        </Typography>}
        {this.state.test?.getStatus() !== 'COMPLETED' && <Typography variant='body1' style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px', color: 'red', fontStyle: 'italic' }}>{this.state.test?.getStatus()}</Typography>}

        <>
          {/* Large Image */}
          {this.state.resultsTests?.map((test, index) => (
            <div key={index} style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {/* NORMAL SAMPLE CHAMBER */}
              {!test?.getTestType()?.getUseVideo() && test?.getTestType()?.getType() === 'SAMPLE_CHAMBER' && (
                test?.getMaskedCircledImagePath() != null ? (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img
                      src={test?.getMaskedCircledImagePath()}
                      onClick={() => this.setState({ imageViewerTest: test })}
                      width='90%'
                      style={{ margin: '2px' }}
                      alt=""
                      onError={(error) => console.log(error)}
                    />
                    <Typography variant='body1' style={{ color: 'slategrey', textAlign: 'center' }}>Click image for full screen and additional images.</Typography>
                  </div>
                ) : (
                  <Typography variant='body1' style={{ color: 'slategrey', fontStyle: 'italic' }}>No image available</Typography>
                )
              )}

              {/* VIDEO SAMPLE CHAMBER */}
              {test?.getTestType()?.getUseVideo() && (
                test?.getProcessedVideoPath() != null ? (
                  <video
                    controls
                    width='90%'
                    style={{ margin: '2px' }}
                  >
                    <source src={test?.getProcessedVideoPath()} type='video/mp4' />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <Typography variant='body1' style={{ color: 'slategrey', fontStyle: 'italic' }}>No video available</Typography>
                )
              )}

              {/* LATERAL FLOW */}
              {test?.getTestType()?.getType() === 'LATERAL_FLOW' && (
                test?.getProcessedImagePath() != null ? (
                  <img
                    src={test?.getProcessedImagePath()}
                    onClick={() => this.setState({ imageViewerTest: test })}
                    width='90%'
                    style={{ margin: '2px' }}
                    alt=""
                  />
                ) : (
                  <Typography variant='body1' style={{ color: 'slategrey', fontStyle: 'italic' }}>No image available</Typography>
                )
              )}
            </div>
          ))}

          {this.state.imageViewerTest != null && (
            <ImageViewer
              src={this.state.imageViewerTest.getTestType()?.getType() === 'SAMPLE_CHAMBER' ? (
                this.state.imageViewerTest.getTestType()?.getUseVideo() ? ([
                  this.state.imageViewerTest.getProcessedVideoPath(),
                  this.state.imageViewerTest.getRawVideoPath()
                ]) : ([
                  this.state.imageViewerTest.getMaskedCircledImagePath(),
                  this.state.imageViewerTest.getCircledImagePath(),
                  ...(this.state.imageViewerTest.getRawImagePaths() ?? [])
                ])
              ) : ([
                this.state.imageViewerTest.getProcessedImagePath(),
                ...(this.state.imageViewerTest.getRawImagePaths() ?? [])
              ])}
              currentIndex={0}
              disableScroll
              closeOnClickOutside={true}
              onClose={() => this.setState({ imageViewerTest: null })}
              backgroundStyle={{ backgroundColor: 'grey' }}
            />
          )}

          {this.state.isOtherImageViewerOpen && (
            <ImageViewer
              src={this.state.inViewFoundParasites.map(foundParasite => foundParasite.getImageUrl())}
              currentIndex={0}
              disableScroll
              closeOnClickOutside={true}
              onClose={() => this.setState({ isOtherImageViewerOpen: false, inViewFoundParasites: [] })}
              backgroundStyle={{ backgroundColor: 'grey' }}
            />
          )}

          {/* Parent Test Link */}
          {this.state.parentTest != null && (
            <>
              <Typography variant='h2' style={{ marginTop: '2em', marginBottom: '1em' }}>View Parent Test</Typography>
              <Button secondary="true" component={Link} to={{ pathname: `/tests/${this.state.parentTest.getId()}`, state: locationState }}>
                {this.state.parentTest?.getTestType()?.getName()}
              </Button>
            </>
          )}

          {/* Individual Test Links for Panel Tests */}
          {this.state.resultsTests?.length > 1 && (
            <>
              <Typography variant='h2' style={{ marginTop: '2em', marginBottom: '1em' }}>View Individual Test Results</Typography>
              {this.state.resultsTests.map(test => (
                <Button key={test.getId()} secondary style={{ width: '100%', marginBottom: '0.5em' }} component={Link} to={{ pathname: `/tests/${test.getId()}`, state: locationState }}>
                  {test.getTestType()?.getName()}
                </Button>
              ))}
            </>
          )}

          {/* Quantitative Results */}
          <Typography variant='h2' style={{ marginTop: '2em' }}>Quantitative Results</Typography>
          <>
            <div style={{ marginTop: '1em' }}>
              {this.state.resultsTests?.map(test => test.getTestedParasites()?.map(testedParasite => (
                <Typography key={testedParasite.getId()} variant='body1' style={{ marginTop: '0.5em' }}>
                  <b>{testedParasite.getParasiteSpecies()?.getName()}</b>:{' '}

                  {/* NORMAL SAMPLE CHAMBER */}
                  {test.getTestType()?.getType() === 'SAMPLE_CHAMBER' && !test.getTestType()?.getUseVideo() && (
                    test.getTestType().showEpg() ? (
                      testedParasite.getCount() + ' EPG'
                    ) : (
                      testedParasite.getCount() > 0 ? 'positive' : 'negative'
                    )
                  ) + (
                    test.getTestType()?.getShowEggsCounted() ? ' (' + testedParasite.getCount() + ' eggs counted)' : ''
                  )}

                  {/* VIDEO SAMPLE CHAMBER */}
                  {test.getTestType()?.getUseVideo() && (
                    testedParasite.getQualitativeResult()
                  )}

                  {/* LATERAL FLOW */}
                  {test.getTestType()?.getType() === 'LATERAL_FLOW' && (
                    testedParasite.getQualitativeResult()
                  )}
                </Typography>
              )))}
            </div>

            <div style={{ marginTop: '1em' }}>
              {this.state.resultsTests?.map(test => test.getTestedParasites()?.map(testedParasite => testedParasite.getFoundParasites()?.length > 0 ? (
                <Accordion key={testedParasite.getId()} defaultExpanded elevation={10} style={{ marginTop: '0.5em' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography style={{ fontWeight: 'bold' }}>{testedParasite.getParasiteSpecies()?.getName()} Stained Images</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {testedParasite.getFoundParasites()?.map(foundParasite => (
                      <img
                        key={foundParasite.getId()}
                        src={foundParasite.getImageUrl()}
                        onClick={() => { } /*this.setState({ isOtherImageViewerOpen: true, inViewFoundParasites: testedParasite.getFoundParasites() })*/}
                        width="300"
                        style={{ margin: '2px' }}
                        alt=""
                      />
                    ))}
                  </AccordionDetails>
                </Accordion>
              ) : null))}
            </div>
          </>

          {/* Test Overview */}
          <>
            <Typography variant='h2' style={{ marginTop: '2em' }}>Test Overview</Typography>

            <LineItem
              description='Date/Time'
              value={this.state.test?.getTimestamp()?.toLocaleString({ ...DateTime.DATETIME_MED, timeZoneName: 'short' })}
            />

            <LineItem
              description='Test ID'
              value={this.state.test?.getId()}
            />

            <LineItem
              description='Patient'
              value={`${this.state.test?.getAnimal().getName() ?? 'Not Provided'} (${this.state.test?.getAnimal().getAnimalSpecies().getName()})`}
            />

            <LineItem
              description='Patient Age at Time of Test'
              value={this.state.test?.getAnimalAge() ?? 'Not Provided'}
            />

            <LineItem
              description='Patient Owner'
              value={`${this.state.test?.getAnimal()?.getOwnerFullName() ?? 'Not Provided'} ${this.state.test?.getAnimal()?.getOwnerEmail() != null ? `(${this.state.test?.getAnimal()?.getOwnerEmail()})` : ''}`}
            />

            <LineItem
              description='Veterinarian'
              value={`${this.state.test?.getVeterinarian()?.getFullName() ?? 'Not Provided'} ${this.state.test?.getVeterinarian()?.getEmail() != null ? `(${this.state.test?.getVeterinarian()?.getEmail()})` : ''}`}
            />

            {this.state.test?.getLabTech() != null &&
              <LineItem
                description='Lab Tech'
                value={`${this.state.test?.getLabTech()?.getFullName() ?? 'Not Provided'} ${this.state.test?.getLabTech()?.getEmail() != null ? `(${this.state.test?.getLabTech()?.getEmail()})` : ''}`}
              />}

            {this.state.test?.getSampleName() != null &&
              <LineItem
                description='Sample Name'
                value={this.state.test.getSampleName()}
              />}

            {this.state.test?.getComments() != null &&
              <LineItem
                description='Comments'
                value={this.state.test.getComments()}
              />}

            <LineItem
              description='Reduction Test'
              value={this.state.test?.getIsReductionTest() ? 'Yes' : 'No'}
            />
          </>

          {/* Test Recommendations */}
          {(this.state.test?.getParentTestId() == null && this.state.test?.getJointParentTestId() == null) &&
          <>
            <Typography variant='h2' style={{ marginTop: '2em', marginBottom: '0.5em' }}> Recommendations and Notes </Typography>

            {this.state.test?.getWasModified() &&
            <Typography variant='body1' style={{ color: 'red', fontStyle: 'italic' }}>The test recommendations have been modified.</Typography>}

            <LineItem
              value={this.state.test?.getTreatments()?.map(treatment => treatment.getName())?.join(', ')}
              description={'Recommended Treatments'}
            />

            <LineItem
              value={this.state.test?.getNotes()}
              description={'Notes'}
            />
          </>}

          {/* Debug Information */}
          <>
            <Typography variant='h2' style={{ marginTop: '2em', marginBottom: '0.5em' }}> Debug Information </Typography>

            <LineItem
              description='Instrument Serial Number'
              value={this.state.test?.getInstrumentSerialNumber()}
            />

            <LineItem
              description='System Info'
              value={this.state.test?.getSystemInfo()}
            />

            <LineItem
              description='Client IP'
              value={this.state.test?.getClientIp() ?? 'Unknown'}
            />

            <LineItem
              description='Recipe ID'
              value={this.state.test?.getRecipe()?.id}
            />

            <LineItem
              description='Image Detection URL'
              value={this.state.test?.getImageDetectionUrl()}
            />

            <LineItem
              description='Confidence Thresholds'
              value={this.state.test?.getConfidenceThresholds()}
            />

            <LineItem
              description='Correction Factor'
              value={this.state.test?.getCorrectionFactor()}
            />
          </>

          {/* Test Auditing */}
          {this.state.test?.getTestType()?.getIsResultsType() &&
          <>
            <LineItem
              description='Audited By Admin'
              value={this.state.test?.getAuditedByAdminUser() ? 
                'PASS' :
                this.state.test?.getAuditedByAdminUser() === null ? 'TODO' :
                  'FAILED'
              }
            />

            <Button secondary="true" component={Link} to={{ pathname: `/tests/${this.id}/audit`, state: { backTo: { pathname: { pathname: `/tests/${this.id}`, state: this.props.location?.state }, description: 'Test Results' } } }}>
              Audit Test
            </Button>
          </>}

        </>
      </Container>
    );
  }
}

Test.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(Test);
