import axios from 'axios';
import {
  KnownError,
  ErrorLog,
  AnimalSpecies,
  Practice,
  PracticeInventoryType,
  User,
  AdminUser,
  AdminUserInvitation,
  Recipe,
  Test,
  TestType,
  Treatment,
  Instrument,
  InventoryType,
  PlatformSetting,
  TestTypeGroup,
  PimsIntegrator
} from '@parasightsysteminc/companion-react';
import { DateTime } from 'luxon';
import store from '../redux/store';
import { clearCookies } from '../redux/actions/authActions';

// Create your axios with credentials object
const BASE_URL = process.env.REACT_APP_SERVER_URL;
const axiosWithCredentialsRaw = axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
});
const axiosWithCredentials = axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
});

// Intercept the success and errors, if needed
axiosWithCredentials.interceptors?.response?.use(
  (response) => {
    return Promise.resolve(
      response?.data != null
        ? response.data === ''
          ? null
          : response.data
        : response
    );
  },
  (error) => {
    if (error?.response?.status === 401) {
      clearCookies(store.dispatch);
    }
    return Promise.reject(
      error?.response?.data?.message || error?.response?.data || error
    );
  }
);

/*
 * Auth Routes
 */

export function login({ email, password }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .post('/auth/login', { email, password })
      .then((res) => {
        resolve(AdminUser.thaw(res));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function createAccount({ token, firstName, lastName, password }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/auth/register', { token, firstName, lastName, password }).then((user) => {
      resolve(AdminUser.thaw(user));
    }).catch(error => {
      reject(error);
    });
  });
}

export function forgotPasswordRequest({ email }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .post('/auth/forgot-password', { email })
      .then((message) => {
        resolve(message);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function forgotPasswordConfirm({ token, password }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .post('/auth/forgot-password/confirm', { token, password })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function checkInvitation({ token }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .get('/auth/check-invitation/' + token)
      .then((invitation) => {
        resolve(AdminUserInvitation.thaw(invitation));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 * Account Routes
 */

export function logout() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .post('/account/logout')
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAccount() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .get('/account')
      .then((user) => {
        resolve(AdminUser.thaw(user));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updatePassword({ password, newPassword }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .post('/account/change-password', { password, newPassword })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 * Admin User Routes
 */

export function inviteAdminUser({ email }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/admin/invitation', { email }).then(() => {
      resolve();
    }).catch((error) => {
      reject(error);
    });
  });
}

export function listAdminInvitations() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .get('/admin/invitation')
      .then(({ results }) => {
        resolve(AdminUserInvitation.thawList(results));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resendAdminInvitation(id) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.patch(`/admin/invitation/${id}/resend`).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function cancelAdminInvitation(id) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.delete(`/admin/invitation/${id}/cancel`).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function getSelf() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/account').then((adminUser) => {
      resolve(AdminUser.thaw(adminUser));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getAdminUser(id) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/admin/' + id).then(adminUser => {
      resolve(AdminUser.thaw(adminUser));
    }).catch(error => {
      reject(error);
    });
  });
}

export function suspendAdminUser(id) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.patch('/admin/' + id, { isSuspended: true }).then(adminUser => {
      resolve(AdminUser.thaw(adminUser));
    }).catch(error => {
      reject(error);
    });
  });
}

export function reactivateAdminUser(id) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.patch('/admin/' + id, { isSuspended: false }).then(adminUser => {
      resolve(AdminUser.thaw(adminUser));
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateAdminUser({ id, ...adminUserChanges }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.patch('/admin/' + id, adminUserChanges).then(adminUser => {
      resolve(AdminUser.thaw(adminUser));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listAdminUsers({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/admin', { params: { options: encodedOptions } }).then(({ results, totalCount }) => {
      resolve({ results: AdminUser.thawList(results), totalCount });
    }).catch(error => {
      reject(error);
    });
  });
}

export function listAdminUserInvitations({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/admin/invitation', { params: { options: encodedOptions } }).then(({ results, totalCount }) => {
      resolve({ results: AdminUserInvitation.thawList(results), totalCount });
    }).catch(error => {
      reject(error);
    });
  });
}

/*
 * Error Routes
 */

export function listKnownErrors({ options = {} } = {}) {
  return new Promise(function (resolve, reject) {
    const encodedOptions = encodeURI(JSON.stringify(options));
    axiosWithCredentials
      .get('/error/known', { params: { options: encodedOptions } })
      .then(({ results }) => {
        resolve(KnownError.thawList(results));
      })
      .catch((error) => {
        console.log('error getting known errors:', error);
        reject(error);
      });
  });
}

export function listErrorLogs({ options = {} } = {}) {
  const encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .get('/error/log', { params: { options: encodedOptions } })
      .then(({ results, totalCount }) => {
        resolve({ results: ErrorLog.thawList(results), totalCount });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateKnownError({ code, ...knownErrorChanges }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .patch('/error/known/' + code, knownErrorChanges)
      .then((knownError) => {
        resolve(KnownError.thaw(knownError));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function createKnownError({
  code,
  isActive,
  message,
  description,
  troubleshootMessage,
}) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .post('/errors/known', {
        code,
        isActive,
        message,
        description,
        troubleshootMessage,
      })
      .then((knownError) => {
        resolve(KnownError.thaw(knownError));
      })
      .catch((error) => {
        reject(error);
      });
  });
}


/*
 * Inventory Routes
 */


/*
 * Practices Routes
 */
/**
 * @param {object} param
 * @returns {Promise<{totalCount: number, results: Practice[]}>}
 */
export function listPractices({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .get('/practice', { params: { options: encodedOptions } })
      .then(({ results, totalCount }) => {
        resolve({ results: Practice.thawList(results), totalCount });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function listPracticesWithInventory({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/practice/inventory', { params: { options: encodedOptions } }).then(({ results, totalCount }) => {
      resolve({ results: Practice.thawList(results), totalCount });
    }).catch(error => {
      reject(error);
    });
  });
}

export function listPracticesMonthlyAccountingCounts({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/practice/monthly-accounting-counts', { params: { options: encodedOptions } }).then(({ results, totalCount }) => {
      resolve({ results: Practice.thawList(results), totalCount });
    }).catch(error => {
      reject(error);
    });
  });
}

export function listPracticeInventoryTypes(practiceId, { options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .get(`/practice/${practiceId}/inventory-type`, { params: { options: encodedOptions } })
      .then(({ results, totalCount }) => {
        resolve({ results: PracticeInventoryType.thawList(results), totalCount });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function listPracticeInstruments(practiceId, { options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .get(`/practice/${practiceId}/instruments`, { params: { options: encodedOptions } })
      .then(({ results, totalCount }) => {
        resolve({ results: Instrument.thawList(results), totalCount });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function listPracticesWithCounts({ options = {} } = {}, startCountDate, endCountDate) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  const params = { options: encodedOptions, includeCounts: true };
  if (startCountDate != null && endCountDate != null) {
    params.startCountDate = startCountDate;
    params.endCountDate = endCountDate;
  }
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .get('/practice/', { params })
      .then(({ results, totalCount }) => {
        resolve({ results: Practice.thawList(results), totalCount });
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function updatePractice({ id, ...practiceChanges }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .patch('/practice/' + id, practiceChanges)
      .then((practice) => {
        resolve(Practice.thaw(practice));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function createPractice({
  identifier,
  name,
  contactName,
  contactEmail,
  contactPhoneNumber,
  isSuspeneded,
  address,
  isDemo
}) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .post('/practice', {
        identifier,
        name,
        contactName,
        contactEmail,
        contactPhoneNumber,
        isSuspeneded,
        address,
        isDemo
      })
      .then((practice) => {
        resolve(Practice.thaw(practice));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getPractice(id) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .get('/practice/' + id)
      .then((practice) => {
        resolve(Practice.thaw(practice));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function suspendPractice(id) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .patch('/practice/' + id, { isSuspended: true })
      .then((practice) => {
        resolve(Practice.thaw(practice));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function reactivatePractice(id) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .patch('/practice/' + id, { isSuspended: false })
      .then((practice) => {
        resolve(Practice.thaw(practice));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updatePracticeInventoryType({ id, ...practiceInventoryTypeChanges }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .patch('/practice/inventory-type/' + id, practiceInventoryTypeChanges)
      .then((practiceInventoryType) => {
        resolve(PracticeInventoryType.thaw(practiceInventoryType));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function savePracticeWhitelabelLogo(practiceId, logo) {
  return new Promise(function (resolve, reject) {
    const formData = new FormData();
    formData.append('file', logo);

    axiosWithCredentials
      .post(`/practice/${practiceId}/whitelabel`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((practice) => {
        resolve(Practice.thaw(practice));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 * User Routes
 */

export function createUser({ firstName, lastName, email, password, practiceId }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .post('/user/create', { firstName, lastName, email, password, practiceId })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getUser(id) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .get(`/user/${id}`)
      .then((user) => {
        resolve(User.thaw(user));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function suspendUser(id) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.patch(`/user/${id}`, { isSuspended: true }).then(user => {
      resolve(User.thaw(user));
    }).catch(error => {
      reject(error);
    });
  });
}

export function reactivateUser(id) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.patch(`/user/${id}`, { isSuspended: false }).then(user => {
      resolve(User.thaw(user));
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateUserPassword(id, { password }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.patch(`/user/${id}/password`, { password }).then(user => {
      resolve(User.thaw(user));
    }).catch(error => {
      reject(error);
    });
  });
}

export function resetUserPassword(id) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.patch(`/user/${id}/reset-password`).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function listUsers({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/user', { params: { options: encodedOptions } }).then(({ results, totalCount }) => {
      resolve({ results: User.thawList(results), totalCount });
    }).catch(error => {
      reject(error);
    });
  });
}

/** @returns {Promise<ReturnType<AnimalSpecies.thawList>>} */
export function listAnimalSpecies() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/animal/species').then(({ results }) => {
      resolve(AnimalSpecies.thawList(results));
    }).catch(error => {
      reject(error);
    });
  });
}

/*
 * Count Routes
 */

/**
 * @param {import('luxon').DateTime} [startDate]
 * @param {import('luxon').DateTime} [endDate]
 * @return {Promise<Record<string, number>>}
 */
export async function countTestsByPractices(startDate, endDate) {
  /** @type {Record<string, number>} */
  const testsByPractice = {};
  const { results } = await listPractices();

  for (let i = 0; i < results.length; i++) {
    const practice = results[i];

    const where = { practiceId: practice.getId() };

    if (startDate || endDate) {
      where.createdAt = {};
      if (startDate && endDate) {
        where.createdAt.$between = [startDate, endDate];
      }
      else if (startDate) {
        where.createdAt.$gte = startDate;
      }
      else {
        where.createdAt.$lte = endDate;
      }
    }

    const { totalCount } = await listTests({
      options: {
        where,
        limit: 0,
      },
    });

    testsByPractice[practice.getName()] = totalCount;
  }

  return testsByPractice;
}

/**
 * @param {import('luxon').DateTime} startDate
 * @param {import('luxon').DateTime} endDate
 * @return {Promise<Record<string, number>>}
 */
export async function countTestsByMonth(
  startDate = DateTime.fromJSDate(new Date(2021, 1)),
  endDate = DateTime.now()
) {
  /** @type {Record<string, number>} */
  const testsByMonth = {};

  /** @type {{month: number, year: number}[]} */
  const monthsToCheck = [];

  const startMonth = startDate.month;
  const startYear = startDate.year;

  const endMonth = endDate.month;
  const endYear = endDate.year;

  let currentMonth = startMonth;
  let currentYear = startYear;

  while (currentYear < endYear || currentMonth <= endMonth) {
    monthsToCheck.push({ month: currentMonth, year: currentYear });

    if (currentMonth === 12) {
      currentMonth = 1;
      currentYear++;
    }

    currentMonth++;
  }

  for (let i = 0; i < monthsToCheck.length; i++) {
    const month = monthsToCheck[i];

    const { totalCount } = await listTests({
      options: {
        where: {
          createdAt: {
            $between: [
              DateTime.fromObject(month).startOf('month').toISO(),
              DateTime.fromObject({ month: month.month, year: month.year })
                .endOf('month')
                .toISO(),
            ],
          },
        },
        limit: 0,
      },
    });

    testsByMonth[`${month.year}-${month.month}`] = totalCount;
  }

  return testsByMonth;
}

/**
 * @param {import('luxon').DateTime} [startDate]
 * @param {import('luxon').DateTime} [endDate]
 * @return {Promise<Record<string, number>>}
 */
export async function countTestsBySpecies(startDate, endDate) {
  /** @type {Record<string, number>} */
  const testsBySpecies = {};

  const speciesList = await listAnimalSpecies();

  for (let i = 0; i < speciesList.length; i++) {
    // eslint-disable-next-line camelcase
    const where = { ['$animal.animalSpecies.id$']: speciesList[i].getId() };

    if (startDate || endDate) {
      where.createdAt = {};
      if (startDate && endDate) {
        where.createdAt.$between = [startDate, endDate];
      }
      else if (startDate) {
        where.createdAt.$gte = startDate;
      }
      else {
        where.createdAt.$lte = endDate;
      }
    }

    const { totalCount } = await countTests({
      options: {
        where,
      },
    });

    testsBySpecies[speciesList[i].getName()] = totalCount;
  }

  return testsBySpecies;
}

/* Test Routes */

export function getTest(id) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get(`/test/${id}`).then(([test, pdfResultsUrl]) => {
      test.pdfResultsUrl = pdfResultsUrl;
      resolve(Test.thaw(test));
    }).catch(error => {
      reject(error);
    });
  });
}

export function submitTestRecommendations(id, { treatmentIds, notes, sendResultsToOwner }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.patch(`/test/${id}`, { treatmentIds, notes, sendResultsToOwner }).then(res => {
      resolve({ test: Test.thaw(res.test), sentEmail: res.sentEmail });
    }).catch(error => {
      reject(error);
    });
  });
}

export function auditTest({ id, issueFlagged }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.patch('/test/' + id + '/audit', { issueFlagged }).then(test => {
      resolve(Test.thaw(test));
    }).catch(error => {
      reject(error);
    });
  });
}

export function batchTestDownload({ startDateTime, endDateTime, practiceIdentifier }) {
  return new Promise(function (resolve, reject) {
    startDateTime = DateTime.fromISO(startDateTime).toUTC().toString();
    endDateTime = DateTime.fromISO(endDateTime).toUTC().toString();
    axiosWithCredentials.get('/test/batch-download', { params: { startDateTime: startDateTime, endDateTime: endDateTime, practiceIdentifier }, responseType: 'blob' }).then(blob => {
      resolve(blob);
    }).catch(error => {
      reject(error);
    });
  });
}

export function listTypeTypeCountsForRange({ startDate, endDate, filterOutSuspended }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/test/counts', { params: { startDate, endDate, filterOutSuspended } }).then((testTypes) => {
      resolve(TestType.thawList(testTypes));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listTestTypeGroups({ options = {} }) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/test/type/group', { params: { options: encodedOptions } }).then(({ results }) => {
      resolve(TestTypeGroup.thawList(results));
    }).catch(error => {
      reject(error);
    });
  });
}

export function toggleTestBillable(id, { billable }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.patch(`/test/${id}/billable`, { billable }).then(test => {
      resolve(Test.thaw(test));
    }).catch(error => {
      reject(error);
    });
  });
}

/**
 * @param {object} param
 * @returns {Promise<{totalCount: number, results: Test[]}>}
 */
export function listTests({ options = {}, filterOutInternal = false } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .get('/test', { params: { options: encodedOptions, filterOutInternal } })
      .then(({ results, totalCount }) => {
        resolve({ results: Test.thawList(results), totalCount });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * @param {object} param
 * @returns {Promise<{totalCount: number}>}
 */
export function countTests({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .get('/test/count', { params: { options: encodedOptions } })
      .then(({ totalCount }) => {
        resolve({ totalCount });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * @param {object} param
 * @returns {Promise<{totalCount: number, results: TestType[]}>}
 */
export function listTestTypes({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .get('/test/type', { params: { options: encodedOptions } })
      .then(({ results, totalCount }) => {
        resolve({ results: TestType.thawList(results), totalCount });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function listTestTypeRecipes(testTypeId, { options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .get(`/test/type/${testTypeId}/recipe`, { params: { options: encodedOptions } })
      .then(({ results, totalCount }) => {
        resolve({ results: Recipe.thawList(results), totalCount });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTestType(id) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/test/type/' + id).then(testType => {
      resolve(TestType.thaw(testType));
    }).catch(error => {
      reject(error);
    });
  });
}

export function deleteRecipe(id) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .delete('/recipe/' + id)
      .then((recipe) => {
        resolve(Recipe.thaw(recipe));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * @param {object} param
 * @returns {Promise<{totalCount: number, results: Test[]}>}
 */
export function listRecipes({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials
      .get('/recipe', { params: { options: encodedOptions } })
      .then(({ results, totalCount }) => {
        resolve({ results: Recipe.thawList(results), totalCount });
      }).catch(error => {
        reject(error);
      });
  });
}

export function getRecipe(id) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get(`/recipe/${id}`).then(res => {
      resolve(Recipe.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function createRecipe({ testTypeId, order, continents, scriptContent }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/recipe', { testTypeId, order, continents, scriptContent }).then((recipe) => {
      resolve(Recipe.thaw(recipe));
    }).catch(error => {
      reject(error);
    });
  });
}

/*
 * Treatment Routes
*/

export function listTreatments({ animalSpeciesId, options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/treatment/', { params: { animalSpeciesId, options: encodedOptions } }).then(({ results, totalCount }) => {
      resolve({ results: Treatment.thawList(results), totalCount });
    }).catch(error => {
      reject(error);
    });
  });
}

/*
  * Instrument Routes
*/

export function getInstrument(serialNumber) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get(`/instrument/${serialNumber}`).then(instrument => {
      resolve(Instrument.thaw(instrument));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listInstruments({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/instrument', { params: { options: encodedOptions } }).then(({ results, totalCount }) => {
      resolve({ results: Instrument.thawList(results), totalCount });
    }).catch(error => {
      reject(error);
    });
  });
}

export function deactivateInstrument(serialNumber) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post(`/instrument/${serialNumber}/deactivate`).then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}

export function toggleIgnoreCartridgeReadySensors(serialNumber, { ignoreCartridgeReadySensors }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post(`/instrument/${serialNumber}/ignore-cartridge-ready-sensors`, { ignoreCartridgeReadySensors }).then((instrument) => {
      resolve(Instrument.thaw(instrument));
    }).catch(error => {
      reject(error);
    });
  });
}

export function toggleIgnoreCartridgeSensors(serialNumber, { ignoreCartridgeSensors }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post(`/instrument/${serialNumber}/ignore-cartridge-sensors`, { ignoreCartridgeSensors }).then((instrument) => {
      resolve(Instrument.thaw(instrument));
    }).catch(error => {
      reject(error);
    });
  });
}

/*
  * Instrument Data Routes
*/

export function getSensorData(instrumentSerial) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get(`/instrument/${instrumentSerial}/sensors`).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    });
  });
}

export function getLogsData(instrumentSerial) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get(`/instrument/${instrumentSerial}/logs`).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    });
  });
}

export function getLogData(instrumentSerial, { fileName }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get(`/instrument/${instrumentSerial}/log/`, { params: { fileName } }).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    });
  });
}

export function getSystemInfo(instrumentSerial) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get(`/instrument/${instrumentSerial}/system-info`).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    });
  });
}

export function getSensorSettings(instrumentSerial) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get(`/instrument/${instrumentSerial}/sensor-settings`).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    });
  });
}

export function setSensorSettings(instrumentSerial, sensorSettings) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post(`/instrument/${instrumentSerial}/sensor-settings`, { sensorSettings }).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    });
  });
}

export function getPumpSettings(instrumentSerial) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get(`/instrument/${instrumentSerial}/pump-settings`).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    });
  });
}

export function setPumpSettings(instrumentSerial, pumpSettings) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post(`/instrument/${instrumentSerial}/pump-settings`, { pumpSettings }).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    });
  });
}

export function getShuttleSettings(instrumentSerial) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get(`/instrument/${instrumentSerial}/shuttle-settings`).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    });
  });
}

export function setShuttleSettings(instrumentSerial, shuttleSettings) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post(`/instrument/${instrumentSerial}/shuttle-settings`, { shuttleSettings }).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    });
  });
}

export function getCameraParams(instrumentSerial) {
  return new Promise(function (resolve, reject) {
    getSystemInfo(instrumentSerial).then(response => {
      resolve(response?.cameraInfo?.cameraParams);
    }).catch(error => {
      reject(error);
    });
  });
}

export function setCameraParams(instrumentSerial, cameraParams) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post(`/instrument/${instrumentSerial}/camera-params`, { cameraParams }).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    });
  });
}

/*
  * Inventory Routes
*/
export function listInventoryTypes() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/inventory/type').then(({ results }) => {
      resolve(InventoryType.thawList(results));
    }).catch(error => {
      reject(error);
    });
  });
}

/*
 * PIMS Routes
 */

// List all available PIMS
export async function listPimsIntegrators() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/practice/pims').then((res) => {
      resolve(PimsIntegrator.thawList(res));
    }).catch(error => {
      reject(error);
    });
  });
}

/**
 * @returns {Promise<import('../../../companion-portal-server/routes/admin/practice/pims').PracticePimsInfo[]>}
 */
export async function bitwerxStatus() {
  return axiosWithCredentials.get('/practice/pims/status');
}

/**
 * @param {string} practiceId
 * @returns {Promise<import('../../../companion-portal-server/routes/admin/practice/pims').PimsIntegrationStatus | null>}
 */
export async function getPracticePimsStatus(practiceId) {
  try {
    const result = await axiosWithCredentialsRaw.get(`/practice/pims/${practiceId}/status`);
    return result.data;
  }
  catch (error) {
    if (error.response.status === 404) {
      return null;
    }
    throw error;
  }
}

/**
 * @param {'Bitwerx'} target
 * @param {string} practiceId
 * @param {string} remotePracticeId
 * @returns {Promise<boolean>}
 */
export async function linkPracticeToPims(pimsIntegratorId, practiceId, remotePracticeId, password) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentialsRaw.post(
      `/practice/pims/${practiceId}/link`,
      {
        pimsIntegratorId,
        remotePracticeId,
        password,
      },
    ).then(res => resolve(res?.data)).catch(error => {
      reject(error);
    });
  });
}

/*
  * Platform Settings
*/
export function getPlatformSettings() {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/platform/settings').then(settings => {
      resolve(PlatformSetting.thawList(settings));
    }).catch(error => {
      reject(error);
    });
  });
}

export function updatePlatformSetting(key, value) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.patch(`/platform/settings/${key}`, { value }).then(settings => {
      resolve(PlatformSetting.thaw(settings));
    }).catch(error => {
      reject(error);
    });
  });
}